import * as React from 'react'

import { Accordion as AccordionItem } from '@thg-commerce/gravity-system'
import { AccordionBorderPlacement } from '@thg-commerce/gravity-system/components/Accordion'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

import {
  AccordionItemContainer,
  AccordionItemContent,
  AccordionTitle,
  Container,
  Subtitle,
  TextContainer,
  Title,
} from './styles'
// import { dataTracking } from './dataTracking'

export enum HorizontalAlignment {
  ALTERNATE = 'alternate',
  LEFT = 'left',
  CENTER = 'center',
}
export interface AccordionItemProps {
  title: string
  content?: string
  isOpen: boolean
  elementContent?: React.ReactElement
  subtitleElement?: React.ReactElement
  focusRef?: React.RefObject<HTMLDivElement>
  borderPlacement?: AccordionBorderPlacement
  textStyle?: TextStyling
}

export interface AccordionContainerProps {
  title?: string
  heading?: 'h1' | 'h2'
  subtitle?: string
  items: AccordionItemProps[]
  alignment: HorizontalAlignment
  focusColour?: 'brand' // will take theme from artemis when implemented
  closeAllOnRender?: boolean
  contentPadding?: boolean
  fitTitles?: boolean
  addTitleRightMarginA11y?: boolean
  className?: string
  accordionTitleCallback?: boolean
}

export const AccordionContainer = (props: AccordionContainerProps) => {
  // const [arrayState, setArrayState] = React.useState<AccordionItemProps[]>(
  //   props.items,
  // )
  //
  // React.useEffect(() => {
  //   if (props.closeAllOnRender) {
  //     setArrayState(props.items.map((item) => ({ ...item, isOpen: false })))
  //   }
  // }, [props.closeAllOnRender, props.items])

  // const setOpenItem = (id: number) => {
  //   const itemsArray = arrayState.filter((item, index) => {
  //     index !== id ? (item.isOpen = false) : (item.isOpen = !item.isOpen)
  //     return item
  //   })
  //   // setArrayState(itemsArray)
  //
  //   const clickedItem = itemsArray.find((_, index) => index === id)
  //
  //   if (clickedItem?.isOpen) {
  //     if (props.accordionTitleCallback) {
  //       dataTracking.sendFaqTitle(clickedItem.title)
  //     } else {
  //       dataTracking.openAccordion()
  //     }
  //   } else if (!props.accordionTitleCallback) {
  //     dataTracking.closeAccordion()
  //   }
  // }

  const accordionItemsMap = props.items.map((item, index) => {
    return (
      <AccordionItem
        title={
          <AccordionTitle
            textStyle={item.textStyle}
            contentPadding={props.contentPadding}
            content={item.title}
            __dangerouslyAllowedTags={['span']}
          />
        }
        focusRef={item.focusRef}
        subtitleElement={item.subtitleElement}
        key={index}
        id={index.toString()}
        isOpen={item.isOpen}
        focusColour={props.focusColour || 'brand'}
        borderPlacement={
          item.borderPlacement || AccordionBorderPlacement.BOTTOM
        }
      >
        {item.content && (
          <AccordionItemContent
            contentPadding={props.contentPadding}
            content={item.content}
          />
        )}
        {item.elementContent}
      </AccordionItem>
    )
  })

  return (
    <Container
      horizontalAlignment={props.alignment}
      className={props.className}
    >
      {(props.title || props.subtitle) && (
        <TextContainer horizontalAlignment={props.alignment}>
          {props.title && (
            <Title
              data-testid="title"
              renderedAs={props.heading || 'h1'}
              horizontalAlignment={props.alignment}
              fitTitles={props.fitTitles}
              addTitleRightMarginA11y={props.addTitleRightMarginA11y}
              content={props.title}
            />
          )}
          {props.subtitle && (
            <Subtitle
              data-testid="subtitle"
              horizontalAlignment={props.alignment}
              fitTitles={props.fitTitles}
            >
              {props.subtitle}
            </Subtitle>
          )}
        </TextContainer>
      )}
      {props.items.length !== 0 && (
        <AccordionItemContainer horizontalAlignment={props.alignment}>
          {accordionItemsMap}
        </AccordionItemContainer>
      )}
    </Container>
  )
}
